import React from 'react';
import {useOutlet} from 'reconnect.js';
import styled from 'styled-components';
import * as Generic from '../../Generic';
import Config from '../../../data.json';
import {serviceCategoryArr, newsCategoryArr} from '../../Category';
import * as JStorage from '../../Actions/JStorage';

const PAGE_NAMES = ['服務項目', '最新消息', '醫生團隊', '美麗見證'];

function AdminArticles(props) {
  const [actions] = useOutlet('actions');
  const [user] = useOutlet('user');

  return (
    <Generic.Resource
      spec={{
        path: '/dashboard/articles',
        name: '文章管理',
        primaryKey: 'id',
        actions: {
          setLoading: actions.setLoading,
          fetchRecords: () =>
            actions.fetchDocuments(
              'Article_Default',
              {},
              [],
              {offset: 0, limit: 350},
              null,
            ),
          fetchRecordById: actions.fetchRecordById,
        },
        columns: [
          {
            title: '首圖',
            key: 'title',
            render: (text, record) => (
              <div>
                <img
                  alt="cover"
                  src={record.image}
                  style={{height: 100, width: 150, objectFit: 'contain'}}
                />
              </div>
            ),
          },
          {
            title: '名稱',
            key: 'title',
            dataIndex: 'title',
          },
          {
            title: '標籤',
            key: 'label',
            dataIndex: 'label',
            render: (text, record) => (
              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {record.label &&
                  record.label.map((l, idx) => (
                    <Label
                      style={
                        (PAGE_NAMES.indexOf(l) >= 0 && {
                          backgroundColor: 'pink',
                        }) ||
                        {}
                      }
                      key={idx}>
                      {l}
                    </Label>
                  ))}
              </div>
            ),
          },
          {
            title: '簡述',
            key: 'outline',
            // dataIndex: 'outline',
            render: (text, record) => (
              <div style={{maxWidth: 500}}>{record.outline}</div>
            ),
          },
        ],
      }}
      onCreate={() => {
        window.open(`${Config.articleEditorHost}?token=${user.token}`);
      }}
      onGoToDetail={(record) => {
        window.open(
          record.id
            ? `${Config.articleEditorHost}?token=${user.token}&id=${record.id}`
            : `${Config.articleEditorHost}?token=${user.token}&id=${record._id.$oid}`,
        );
      }}
      renderDetail={() => null}
      renderCustomIntro={() => {
        return (
          <IntroBox>
            <div style={{marginBottom: 10, fontWeight: 'bold'}}>
              請依照文章分類，掛上相對應的標籤
            </div>
            <div className="row" style={{padding: 5}}>
              <Label style={{backgroundColor: 'pink'}}>服務項目</Label>
              <div className="title">/</div>
              {serviceCategoryArr.map((service, idx) => (
                <Label key={idx}>{service.name}</Label>
              ))}
            </div>
            <div className="row" style={{padding: 5}}>
              <Label style={{backgroundColor: 'pink'}}>最新消息</Label>
              <div className="title">/</div>
              {newsCategoryArr.map((name, idx) => (
                <Label key={idx}>{name}</Label>
              ))}
            </div>
            <div className="row" style={{padding: 5}}>
              <Label style={{backgroundColor: 'white', color: 'black'}}>
                關於我們
              </Label>
              <div className="title">/</div>
              <Label>公益活動</Label>
            </div>
            <div className="row" style={{padding: 5}}>
              <Label style={{backgroundColor: 'pink'}}>醫生團隊</Label>
              <div className="title">/</div>
            </div>
            <div className="row" style={{padding: 5}}>
              <Label style={{backgroundColor: 'pink'}}>美麗見證</Label>
              <div className="title">/</div>
            </div>
          </IntroBox>
        );
      }}
      {...props}
    />
  );
}

let IntroBox = styled.div`
  border: 2px solid pink;
  padding: 20px;
  letter-spacing: 1px;

  & .title {
    display: inline-block;
    margin-right: 10px;
  }
`;

let Label = styled.span`
  margin: 3px;
  background-color: #4b4b4b;
  border-radius: 5px;
  padding: 5px;
  color: #fff;
  white-space: nowrap;
`;

export default AdminArticles;
